.samplers-chooser-wrapper {
    margin: 8px 0 3px;
    float: left;
    clear: both;
    width: 100%;
    padding: 4px 0;
    overflow: hidden;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    //Instead of the line below you could use @includeborder-radius($radius, $vertical-radius)
    border-radius: 5px;
    background: $color-4;
    color: $color-2;
    select {
        margin: 0 10px;
        border: 1px solid $color-2;
        
        width: 411px;
        
        @media (max-width: $screen-xs-min - 1px) {
            max-width: 92% !important;
        }
        
        height: 18px;
        font-size: 10px;
        font-family: Arial, Helvetica, sans-serif;
        background: url(../simg/samplers-more.png) no-repeat scroll 336px 2px $color-4;
        color: $color-2;
    }
}