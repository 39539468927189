html {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    height: 100%;
    text-align: left;
    font-size: 15px;
    // font-family: Arial, Helvetica, sans-serif;
    font-family: "Open Sans", sans-serif;
    color: $colorWhite;
}
body {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    height: 100%;
    text-align: left;
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
    color: $colorWhite;
    background-color: $colorBlack;
    font-size: 1em;
    &.nooverflowx {
        overflow-x: auto !important;
    }
    // visibility: visible;
}

a:focus, a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    border: 0;
    outline : 0;
    // text-decoration: none;
}

a {
    @include transition-property(color, background-color);
    @include transition-duration(.5s);
    
    &:hover {
    }
}

.active a {
    text-decoration: underline;
}

img {
  vertical-align: baseline;
  transition-property: opacity;
  transition-duration: .5s;
}

.btn {
    line-height: normal;
}

button {
    cursor: pointer;
}

div {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
span {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
h1, .h1 {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    img.arrow-title {
        margin: 0 11px 0 10px;
    }
    
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 20px;
    
}
h2 {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    img.arrow-title {
        margin: 0 11px 0 10px;
    }
}
h3 {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
h4 {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
h5 {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
h6 {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
p {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    
    margin-bottom: 10px;
    
}
blockquote {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
pre {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
a {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    img {
        border: 0;
    }
    &:link {
        text-decoration: none;
        color: $colorSecLight;
        img {
            border: 0;
        }
    }
    &:visited {
        text-decoration: none;
        color: $colorSecLight;
        img {
            border: 0;
        }
    }
    &:active {
        text-decoration: none;
        color: $colorSecLight;
    }
    &:hover {
        text-decoration: none;
        color: lighten($colorSecLight, 10%);
    }
    &.active {
        text-decoration: none;
    }
    // &.btn {
    //     position: relative;
    //     margin: 0;
    //     padding: 4px 18px;
    //     font-size: 10px;
    //     text-transform: uppercase;
    //     border: 0;
    //     -webkit-border-radius: 5px;
    //     -moz-border-radius: 5px;
    //     //Instead of the line below you could use @includeborder-radius($radius, $vertical-radius)
    //     border-radius: 5px;
    //     cursor: pointer;
    //     background-color: $color-0;
    //     color: $color-2;
    // }
}
em {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
font {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
img {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
strong {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
ol {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style-type: none;
    list-style-position: inside;
}
ul {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style-type: none;
    list-style-position: inside;
}
li {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
form {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
table {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
tr {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
th {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}
