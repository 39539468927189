$colorBlack: #000;
$colorWhite: #fff;

$colorPri: #fff;
$colorSec: #9d0d15;
$colorSecLight: lighten(#9d0d15, 10%);
$colorTer: #1b1b1b;

$colorRed: #ff0000;
$colorGray: #bebebe;

$color-0: #000;
$color-1: #d2d2d2;
$color-2: #fff;
$color-3: #ff1f1f;
$color-4: #0a6df4;
$color-5: #b6b5b5;
$color-6: #000;
$color-7: #f00;
$color-8: #3786f0;
$color-9: #777;
$color-10: #8a8989;
$color-11: #e2e2e2;

$navbar-inverse-link-hover-color: $colorSec !default;
// $navbar-inverse-bg: transparent !default;

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700,700italic&subset=latin,latin-ext');
@import "theme-variables";
// @import "compass";
@import "bootstrap";
//colors

$screen-xxs-max: $screen-xs - 1px;

// ELEMENTS
@import "elements/table-responsive";

@import "elements/elements";

@import "elements/embed-responsive";


// COMPONENTS
@import "components/scaffolding";

@import "components/classes";

@import "components/blocks";

@import "components/left-right";

@import "components/forms";

@import "components/galleries";

@import "components/content";


// PRODUCT
@import "components/products/product-boxes";

@import "components/products/product-category";

@import "components/products/product-single";

@import "components/products/product-gallery";

@import "components/products/product-sampler";

@import "components/products/cart-fast";

@import "components/products/cart";

@import "components/products/order";



@import "components/footer";

@import "components/window";

@import "components/facebook";

@import "components/banner-top";

@import "components/menu-top";

@import "components/menu-bottom";
