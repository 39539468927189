.fb {
    background: url(../simg/base/fbslide.png) no-repeat scroll 0 0 transparent !important;
    float: right;
    height: 420px;
    padding: 0;
    position: fixed;
    
    right: -205px;
    
    top: 300px;
    width: 247px;
    z-index: 1002;
    
    @media (max-width: $screen-xs-max) {
        //background: url(../simg/base/icon-facebook.png) no-repeat scroll 0 0 transparent !important;
        float: right;
        height: 34px;
        padding: 0;
        position: absolute;
        left: 15px;
        top: 9px;
        width: 44px;
        z-index: 9999999;
        border-radius: 4px;
        right: auto;
        border: 1px solid $navbar-inverse-toggle-border-color;
        background-color: #0e0e11 !important;
        background-image: url('../simg/base/icon-facebook.png') !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;
    }
    
    a {
        display: block;
        width: 100%;
        height: 100%;
    }
    iframe {
        background: none repeat scroll 0 0 $color-2;
    }
}