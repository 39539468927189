#window {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // z-index: 999;
    z-index: 99999;
    #window-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-2;
        opacity: .75;
        cursor: pointer;
    }
    #window-inner {
        position: relative;
        float: left;
        width: 80%;
        padding: 20px 20px 10px 20px;
        margin: 0 auto;
        background-color: $colorBlack;
        color: $colorWhite;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        //Instead of the line below you could use @includeborder-radius($radius, $vertical-radius)
        border-radius: 5px;
        //Instead of the line below you could use @includebox-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        box-shadow: 3px 3px 3px $color-9;
        -moz-box-shadow: 3px 3px 3px $color-9;
        -webkit-box-shadow: 3px 3px 3px $color-9;
        #window-content {
            float: left;
            width: 100%;
            margin-top: 10px;
            .error {
                float: left;
                width: 300px;
                margin: -10px 0 7px;
                padding: 5px 10px;
                //Instead of the line below you could use @includeborder-radius($radius, $vertical-radius)
                border-radius: 5px 5px 5px 5px;
                font-weight: bold;
                background-color: $color-7;
                color: $color-2;
            }
            h2 {
                float: left;
                width: 285px;
                margin-top: -10px;
                font-weight: normal;
                background-color: $colorBlack;
                color: $colorWhite;
            }
            .window-content {
                float: left;
                clear: both;
                width: 97%;
                padding: 0 15px 0 0;
                margin: 10px 0;
                max-height: 450px;
                text-align: justify;
                overflow-x: hidden!important;
                p+p {
                    margin-top: 15px;
                }
            }
        }
        #window-close {
            position: absolute;
            top: 25px;
            right: 20px;
            width: 9px;
            height: 11px;
            background-image: url(../simg/btn-close-window.png);
            background-repeat: no-repeat;
            background-position: 0 0;
            cursor: pointer;
        }
    }
}