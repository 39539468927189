#form-order {
    float: left;
    clear: both;
    width: 100%;
    margin: 0 0 0 7px;
    padding: 20px 0 0;
    
    @media (max-width: $screen-xs-max) {
        width: 90%;
        margin: 0 5% 0 5%;
    }
    
    @media (max-width: $screen-xs-min - 1px) {
        width: 95%;
        margin: 0 0 0 2%;
    }
    
    @media (max-width: 322px) {
        width: 100%;
        margin: 0 0 0 0;
    }
    
    
    fieldset {
        @include clearfix;
        // background-color: green;
        float: left;
        width: 49%;
        margin: 0 0 10px 0;
        padding: 0;
        
        @media (max-width: $grid-float-breakpoint-max) {
            width: 100%;
        }
        
        &.col1 {
            // width: 220px;
            @media (min-width: $screen-md-min) {
                padding: 0 25px 0 0;
                // margin-right: 25px;
            }
        }
        &.col2 {
            // width: 300px;
            // margin-left: 25px;
            
            @media (min-width: $screen-md-min) {
                padding: 0 0 0 25px;
            }
            
        }
        &.col3 {
            // width: 300px;
            // margin-left: 25px;
            
            @media (min-width: $screen-md-min) {
                padding: 0 0 0 25px;
                // margin-left: 0;
            }
            
        }
        &.col-max {
            width: 100%;
            min-height: 80px;
            padding-left: 25px;
            position: relative;
            @media (max-width: $screen-xs-max) {
                margin-left: 0;
            }
        }
        
        &.submit-info {
            width: 100%;
            clear: both;
        }
    }
    .form-field {
        float: left;
        clear: both;
        width: 100%;
        padding: 0 0 10px 0;
        &.i-error {
            color: $colorRed;
        }
        &.i-conditions {
            text-transform: none;
            font-size: 15px;
            text-align: justify;
            * {
                display: inline;
                width: auto;
                float: none;
                clear: none;
                text-transform: none;
                vertical-align: middle;
            }
        }
        label {
            float: left;
            clear: both;
            margin: 0 0 5px 0;
            text-transform: uppercase;
            font-weight: 400;
        }
        input {
            float: left;
            clear: both;
            // width: 216px;
            width: 100%;
            height: 35px;
            margin: 0;
            padding: 5px;
            border: 1px solid $colorWhite;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 15px;
            background-color: transparent;
            color: $colorWhite;
            &#i-submit {
                display: block;
                float: right;
                width: auto;
                line-height: 19px;
                padding: 0 18px;
                vertical-align: middle;
                // background-color: $color-0;
                color: $colorWhite;
                
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                background-color: $colorSec;
                border-radius: 50%;
                width: 80px;
                height: 80px;
                font-size: 15px;
                padding: 5px;
                line-height: 15px;
                text-align: center;
                font-weight: 700;
                
                color: $colorWhite;
                transition-property: background-color;
                transition-duration: .5s;
                outline-color: transparent;
                &:hover {
                    background-color: $colorSecLight;
                }
            }
        }
        textarea {
            float: left;
            clear: both;
            width: 100%;
            height: 18px;
            margin: 0;
            padding: 5px;
            border: 1px solid $colorWhite;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 15px;
            background-color: transparent;
            color: $colorWhite;
            // width: 296px;
            height: 125px;
            // min-width: 296px;
            // max-width: 296px;
            // min-height: 88px;
            // max-height: 88px;
            padding: 5px;
        }
        select {
            float: left;
            clear: both;
            width: 216px;
            height: 18px;
            margin: 0;
            padding: 0 1px;
            border: 1px solid $colorWhite;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 15px;
            background-color: transparent;
            color: $colorWhite;
            width: 286px;
            padding: 2px 2px 2px 11px;
            option {
                width: auto;
                padding: 3px 13px;
            }
        }
    }
}

#order-items .cart-full .cart-items table { 
    width: 100%;
    tbody tr {
        td {
            padding-top: 10px !important;
            &:first-child {
                padding-top: 35px;
                padding-left: 11px;
                // width: 190px;
            }
        }
        
        .product-cart-qty {
            white-space: nowrap;
        }
        
        .product-cart-remarks {
            // width: 152px;
        }
        .product-cart-value-total-label {
            font-weight: bold;
            font-size: 15px;
            text-align: right;
            padding-right: 10px;
        }
        .product-cart-value-total {
            font-weight: bold;
            font-size: 15px;
            span {
                display: block;
                width: inherit;
                white-space: nowrap;
                overflow: visible;
            }
        }
        &:first-child td {
            padding-top: 20px!important;
        }
    }
    thead tr:first-child th:first-child {
        text-indent: 0;
    }
}
#order-form .form-error {
    clear: both;
    // float: left;
    margin: 50px 5px 5px 7px;
    width: 100%;
}
