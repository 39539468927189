#category-products {
    position: relative;
    float: left;
    width: 100%;
    
    // --- width: 723px;
    
    .products-filter {
        position: absolute;
        right: 20px;
        top: 0;
        
        @media (max-width: $screen-xs-max) {
            position: static;
            right: 0;
            margin-top: 40px;
        }
        
        .products-filter-sort {
            float: left;
            margin: 0;
        }
        .products-filter-search {
            float: left;
            
            
            margin: 0 0 0 12px;

            @media (max-width: $screen-xs-max) {
                float: right;
                margin: 0 0 0 0;
            }
            
            @media (max-width: 407px) {
                margin: 15px 0 0 0;
            }
            
            input {
                // width: 191px;
                width: 218px;
                
                // height: 20px;
                height: 22px;
                
                padding: 0 22px 0 5px;
                border: 1px solid $colorWhite;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 10px;
                text-transform: uppercase;
                // background-image: url(../simg/icn-filter-search.png);
                // background-repeat: no-repeat;
                // background-position: 202px 4px;
                background-color: $colorBlack;
                color: $colorWhite;
                border-radius: 0;
                outline-color: transparent;
            }
        }
    }
    .category {
        float: left;
        clear: both;
        width: 100%;
        min-height: 373px;
        .category-description {
            float: left;
            clear: both;
            
            // width: 703px;
            width: 100%;
            
            margin: 15px 0 0;
        }
    }
    .product-promoted {
        float: left;
        clear: both;
        width: 100%;
    }
}