.label {
    display: block;
    float: left;
    clear: both;
    height: 14px;
    white-space: nowrap;
    margin: 0;
    padding: 4px 18px;
    font-size: 10px;
    text-transform: uppercase;
    text-align: left;
    background-color: $color-0;
    color: $color-1;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    //Instead of the line below you could use @includeborder-radius($radius, $vertical-radius)
    border-radius: 5px;
    &.white {
        background-color: $color-2;
        color: $color-0;
    }
}

.btn {
    position: relative;
    margin: 0;
    padding: 4px 18px;
    font-size: 10px;
    text-transform: uppercase;
    border: 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    //Instead of the line below you could use @includeborder-radius($radius, $vertical-radius)
    border-radius: 5px;
    cursor: pointer;
    background-color: $color-0;
    color: $color-2;
    &:hover,&:focus {
        color: $color-2;
    }
    .arrow {
        position: absolute;
        display: block;
        right: 7px;
        top: 7px;
        width: 11px;
        height: 10px;
        background-repeat: no-repeat;
        background-position: 0 0;
    }
}
.arrow-black-right {
    background-image: url(../simg/arrow-thick-black-right.png);
}
.arrow-white-right {
    background-image: url(../simg/arrow-thick-white-right.png);
}
.arrow-white-down {
    background-image: url(../simg/arrow-thick-white-down.png);
}
.arrow-white-up {
    background-image: url(../simg/arrow-thick-white-up.png);
}
.message {
    float: left;
    clear: both;
    width: 100%;
    margin: 10px 0;
}
.box {
    display: block;
    float: left;
    clear: both;
}
.qtip {
    vertical-align: text-top;
    font-size: 10px;
    font-weight: normal;
    cursor: help;
}
.logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 340px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: url(../simg/base/dogo-logo.png);
    a {
        display: block;
        float: left;
        clear: both;
        width: 100%;
        height: 100%;
    }
}

#fancybox-content .label {
    width: 414px;
    margin-bottom: 10px;
}
.more-link .btn {
    float: right;
    width: auto;
    margin-right: -9px;
}

.dk_theme_idealmeble {
    .dk_toggle {
        height: 22px;
        @media (max-width: $screen-xs-max) {
            width: 173px !important;
            padding-left: 6px;
        }
    }
    
    .dk_options {
        a {
            color: $color-5;
        }
    }
    
}

.fancybox-overlay {
    z-index: 999999999;
}


.circleImgWrp {
    max-width: 100%;
    background-color: $colorWhite;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    transition-property: background-color;
    transition-duration: .5s;
    
    &:hover,
    &:focus {
        background-color: $colorSec;
    }
    
    &--variants-table {
        width: 168px;
        height: 168px;
        margin: 0 auto;
    }
}

.cartBtn {
    max-width: 100%;
    background-color: $colorWhite;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:hover,
    &:focus {
        background-color: $colorSec;
    }
    
    &--variants-table {
        width: 70px;
        height: 70px;
        margin: 0 auto;
    }
    .cartBtnBg {
        width: 42px;
        height: 34px;
        background-image: url('../simg/catalog/cart.png');
        background-repeat: no-repeat;
        background-position: center;
        
    }
}

.circleBtn {
    max-width: 100%;
    background-color: $colorWhite;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:hover,
    &:focus {
        background-color: $colorSec;
    }
    
    
    width: 70px;
    height: 70px;
    margin: 0 auto;
    
    .circleBtnBg {
        width: 45px;
        height: 45px;
        background-repeat: no-repeat;
        background-position: center;
    }
}


.circleBtn--Cart {
    .circleBtnBg {
        background-image: url('../simg/catalog/btn-cart.png');
    }
}

.circleBtn--Mail {
    .circleBtnBg {
        background-image: url('../simg/catalog/btn-mail.png');
    }
}

.circleBtn--GG {
    .circleBtnBg {
        background-image: url('../simg/catalog/btn-gg.png');
    }
}

.circleBtn--Question {
    .circleBtnBg {
        background-image: url('../simg/catalog/btn-question.png');
    }
}

.circleBtn--Transport {
    .circleBtnBg {
        background-image: url('../simg/catalog/btn-transport.png');
    }
}

.circleBtn--Pdf {
    .circleBtnBg {
        background-image: url('../simg/catalog/btn-pdf.png');
    }
}

.circleBtn--Manual {
    .circleBtnBg {
        background-image: url('../simg/catalog/btn-manual.png');
    }
}