.fixed {
    position: fixed!important;
    left: 0!important;
    top: 0!important;
}

.display-none {
    display: none;
}

.visibility-hidden {
    visibility: hidden;
}