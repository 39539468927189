input {
    @include transition-property(background-color, color);
    @include transition-duration(.5s);
}

.form-error {
    float: left;
    clear: both;
    width: 100%;
    color: $colorRed;
    margin: 0 0 15px 0;
    ul {
        list-style-type: disc;
        margin: 5px 10px 10px;
        li:first-letter {
            text-transform: uppercase;
        }
    }
}
.form-correct {
    float: left;
    clear: both;
    width: 100%;
    color: $colorWhite;
    margin: 0 0 10px 0;
}
.i-error {
    input {
        border: 1px solid $colorRed!important;
    }
    textarea {
        border: 1px solid $colorRed!important;
    }
    select {
        border: 1px solid $colorRed!important;
    }
}

input[type="text"],
textarea {
    &::placeholder {
        color: darken($colorWhite, 40%);
    }
}

#form-contact {
    float: left;
    clear: both;
    width: 320px;
    max-width: 100%;
    // background-color: #191313;
    padding: 0;
    margin-top: 0!important;
    // margin-left: 20px;
    fieldset {
        float: left;
        width: 50%;
        width: 100%;
        margin: 0 0 10px 0;
        padding: 0;
        // background-color: #232531;
        &.col1 {
            width: 100%;
        }
        &.col2 {
            width: 131px;
        }
        &.submit-info {
            width: 100%;
            clear: both;
        }
    }
    .form-field {
        float: left;
        clear: both;
        width: 100%;
        padding: 0 0 10px 0;
        &.i-error {
            color: $colorRed;
        }
        &.adres {
            display: none;
        }
        label {
            float: left;
            clear: both;
            margin: 0 0 5px 0;
            text-transform: uppercase;
        }
        
        input,
        textarea,
        select {
            &:focus {
                outline-color: $colorSec;
            }
        }
        
        input {
            float: left;
            clear: both;
            
            width: 100%;
            height: 32px;
            margin: 0;
            padding: 5px;
            border: 1px solid darken($colorWhite, 20%);
            font-family: Arial, Helvetica, sans-serif;
            font-size: 18px;
            background-color: transparent;
            color: $colorWhite;
            &#i-submit {
                float: right;
                width: auto;
                padding: 7px 30px;
                background-color: $colorSec;
                color: $colorBlack;
                font-weight: 700;
                font-size: 16px;
                text-transform: uppercase;
                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                    background-color: lighten($colorSec, 20%);
                    color: $colorWhite;
                }
            }
        }
        textarea {
            float: left;
            clear: both;
            width: 100%;
            margin: 0;
            padding: 5px;
            border: 1px solid darken($colorWhite, 20%);
            font-family: Arial, Helvetica, sans-serif;
            font-size: 18px;
            background-color: transparent;
            color: $colorWhite;
            height: 180px;
            resize: vertical;
        }
        select {
            float: left;
            clear: both;
            width: 100%;
            height: 18px;
            margin: 0;
            padding: 0 1px;
            border: 1px solid darken($colorWhite, 20%);
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
            background-color: transparent;
            color: $color-0;
            width: 286px;
            padding: 2px 2px 2px 11px;
            option {
                width: auto;
                padding: 3px 13px;
            }
        }
    }
}