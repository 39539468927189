.content {
    position: relative;
    // float: left;
    margin: 0;
    padding: 0;
    
    @media (max-width: $screen-xs-max) {
        width: 100%;
    }
    
}
.content-form {
    // position: absolute;
    top: 30px;
    // top: 50px;
    // left: 500px;
}

.content + .content {
    margin-top: 30px;
}

.content-article + .content-article {
    margin-top: 30px;
}

#scroll-top {
    display: inline-block;
    border-radius: 5px;
    position: fixed;
    z-index: 9999;
    bottom: 0;
    right: 0;
    margin: 1.9em;
    font-size: 20px;
    padding: 8px 15px 4px 15px;
    text-decoration: none;
    letter-spacing: 1px;
    text-transform: uppercase;
    background: $colorSec;
    color: $colorWhite;

    &:hover {
        @include box-shadow(0px 0px 5px rgba($color-11, 0.5));
        background: $colorBlack;
        color: $colorWhite;
    }

    @media only screen and (max-width: $screen-xs-max) {
        margin-bottom: 15px;
        margin-right: 15px;
    }
}