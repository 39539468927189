.IdHeader .menu.menu-top {
    float: left;
    clear: both;
    width: 100%;
    
    // height: 33px;
    min-height: 172px;
    
    @media (max-width: $grid-float-breakpoint-max) {
        
        height: 50px;
        min-height: 50px;
        // padding-bottom: 16%;
    }
    
    // overflow: hidden;
    background: $colorBlack;

    z-index: 999;
    
    @media (max-width: $grid-float-breakpoint-max) {
        .nav.navbar-nav {
            > li {
                > a{
                    font-size: 18px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    text-decoration: none;
                    color: $colorPri;
                    text-transform: uppercase;
                    &:hover,
                    &:focus {
                        color: lighten($colorSec, 20%);
                    }
                }
                &.active {
                    > a {
                        color: lighten($colorSec, 20%);
                    }
                }
            }
        }
    }
    
    @media (min-width: $grid-float-breakpoint) {  
        
        height: 172px;
        
        .nav.navbar-nav {
            // display: block;
            clear: both;
            float: right;
            // width: 950px;
            
            margin: 0 auto;
            padding: 0;
            list-style-type: none;
            list-style-position: inside;
            overflow: visible;
            background-color: transparent;
            > li {
                display: block;
                float: left;
                font-weight: 400;
                // height: 9px;
                // @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                    // height: 25px;
                // }
                
/*         line-height: 15px; */
                overflow: visible;
                > a {
                    display: block;
                    
                    // margin-top: -4px;
                    margin-top: -3px;
                    
                    font-size: 18px;
                    
                    text-decoration: none;
                    text-transform: uppercase;
                    color: $colorWhite;
                    overflow: visible;
                    
                    padding:0;
                    
                    line-height: normal;
                    
                    // @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                        
                        // line-height: 3.1;
                    // }
                    
                    &:hover {
                        color: lighten($colorSec, 20%);
                        text-decoration: none;
                    }
                    &.active {
                        color: lighten($colorSec, 20%);
                        text-decoration: none;
                    }
                    
                    
                }
                &.active > a {
                    color: lighten($colorSec, 20%);
                    text-decoration: none;
                }
            }
            > li + li {
                margin-left: 13px;
                padding-left: 16px;
                // border-left: 1px solid $color-2;
                &:before {
                    content: "\00b7";
                    color: $colorSec;
                    position: absolute;
                    left: -3px;
                    font-size: 40px;
                    line-height: 18px;
                }
                // @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                    // padding-left: 10px;
                // }
            }
        }
    }
}


.menu-top {
    .navbar {
        z-index: 9999;

        .navbar-header {
            .navbar-toggle.collapsed {
                backface-visibility: hidden;
                transform: translateZ(0) rotate(0deg);
            }
            .navbar-toggle {
                transition-property: transform;
                transition-duration: 0.5s;
                transform: rotate(180deg);
                margin-right: 15px;
            }
        }
    }
    .navbar-collapse {
        
    }
}



// --------------------------------------------------------------------------------------------------------------------

.IdHeader {
    .menu.menu-top {
        
        .dropdown-menu-offer {
            position: static;
            top: 100%;
            left: 0;
            z-index: 1000;
            // display: none;
            float: none;
            min-width: 0;
            padding: 5px 0;
            margin: 2px 0 0;
            list-style: none;
            font-size: 18px;
            text-align: left;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            box-shadow: none;
            background-clip: padding-box;
            text-transform: uppercase;
            
            @media (min-width: $grid-float-breakpoint) {
                float: right;
                position: absolute;
                background-color: $colorSec;
                border-radius: 10px;
            }
        }
        
        .dropdown-menu-offer {
            > li {
                display: block;
                float: none;
                margin: 0;
                padding: 0;
                border-bottom: 1px solid $colorBlack;
                &:last-child {
                    border-bottom: none;
                }
                > a {
                    color: $colorWhite;
                    background-color: transparent;
                    &:hover,
                    &:focus {
                        background-color: transparent;
                        color: $colorSec;
                        @media (min-width: $grid-float-breakpoint) {
                            color: $colorBlack;
                        }
                    }
                }
                &.active {
                    > a {
                        color: $colorWhite;
                        background-color: transparent;
                        &:hover,
                        &:focus {
                            background-color: transparent;
                            color: $colorSec;
                            @media (min-width: $grid-float-breakpoint) {
                                color: $colorBlack;
                            }
                        }
                        // background-color: transparent;
                        // color: $colorSec;
                        // @media (min-width: $grid-float-breakpoint) {
                        //     color: $colorBlack;
                        // }
                    }
                }
            }
            > li + li {
                // border: 0;
                margin: 0;
                padding: 0;
            }
        }
    }
}

.Navbar-Brand {
    padding: 0;
    @media (max-width: $grid-float-breakpoint-max) {
        max-width: 37%;
    }
}

.Navbar-Brand-Img {
    max-width: 100%;
    @media (max-width: $grid-float-breakpoint-max) {
        max-height: 50px;
    }
}

.IdHeader .menu.menu-top, .FooterMenuSocial {
    .SocialMenuWrp {
        position: relative;
        .SocialMenu {
            position: absolute;
            right: 25px;
            top: 20px;
            z-index: 10000;
            @media (max-width: $grid-float-breakpoint-max) {
                top: 15px;
                right: 65px;
            }
            > ul {
                float: none;
                height: 25px;
                // width: 350px;
                // background-color: green;
                display: inline-block;
                width: auto;
                > li {
                    color: $colorSec;
                    display: inline-block;
                    float: right;
                    margin: 0 6px 0 6px;
                    &:before {
                        display: none;
                    }
                    > a {
                        color: $colorSec;
                        .hover {
                            margin-left: 5px;
                            position: relative;
                            z-index: 1;
                            top: -2px;
                        }
                        .hidden-title + .hover {
                            display: none;
                        }

                        &:hover {
                            color: lighten($colorSec, 20%);
                        }
                        .fa {
                            font-size: 24px;
                        }
                    }
                    + li {
                        margin: 0 6px 0 6px;
                        padding: 0;
                    }
                }
            }
        }
    }
}

.IdHeader .menu.menu-top{
    .SocialMenuWrp {
        
        .SocialMenu {

            > ul {

                > li {
                    > a {
                        
                        .hover {
                            @media (max-width: $grid-float-breakpoint-max) {
                                display: none;
                            }
                            .back-middle {
                                color: lighten($colorSec, 25%);
                            }
                        }
                        
                    }
                }
            }
        }
    }
}

.FooterMenuSocial {
    .SocialMenuWrp {
        
        .SocialMenu {

            > ul {

                > li {
                    @media (max-width: $grid-float-breakpoint-max) {
                        display: block;
                    }
                    > a {
                        
                        .hover {
                            display: none;
                        }
                        
                    }
                }
            }
        }
    }
}