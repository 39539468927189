@mixin table-responsive($media-screen-size: $screen-xs) {
    
    // @if $media-screen-size != false {
    @media only screen and (max-width: $media-screen-size) {
    // }
    
    table-layout: fixed;
    border-collapse: collapse;
    margin-bottom: 10px;
    float: left;
    width: 100%;
    clear: both;
    word-wrap: break-word;

    td {
        padding: 0px 15px 0px 15px;

            &:first-child {
                padding-left: 0px;
            }

            &:last-child {
                padding-right: 0px;
            }
    }

    img {
            display: inline-block;
            width: auto;
            height: auto;
            max-width: 100%;
            margin: 0px auto;
            padding: 0px;
            border: none;
    }

    a {
            img {
                @include transition-duration(0.25s);
                -moz-backface-visibility: hidden;
            }

            &:hover {
                img {
                    opacity: 0.9;
                    -moz-backface-visibility: hidden;
                }
            }
    }

    @media only screen and (max-width: $screen-sm-max) {
            td {
                padding: 0px 10px 0px 10px;

                &:first-child {
                    padding-left: 0px;
                }

                &:last-child {
                    padding-right: 0px;
                }
            }
    }

    @media only screen and (max-width: $screen-xs-max) {
            td {
                width: 100% !important;
                padding: 0px 5px 0px 5px;

                &:first-child {
                    padding-left: 0px;
                }

                &:last-child {
                    padding-right: 0px;
                }
            }
    }

    @media only screen and (max-width: $screen-xs) {
            td {
                float: left;
                clear: both;
                width: 100% !important;
                padding: 0px 0px 15px 0px;

                &:last-child {
                    padding-bottom: 0px;
                }
            }
    }
    
    // @if $media-screen-size != false {
    }
    // }
    
}

table.responsive {
    table-layout: fixed;
    border-collapse: collapse;
    margin-bottom: 10px;
    float: left;
    width: 100%;
    clear: both;
    word-wrap: break-word;

    td {
        padding: 0px 15px 0px 15px;

        &:first-child {
            padding-left: 0px;
        }

        &:last-child {
            padding-right: 0px;
        }
    }

    img {
        display: inline-block;
        width: auto;
        height: auto;
        max-width: 100%;
        margin: 0px auto;
        padding: 0px;
        border: none;
    }

    a {
        img {
            @include transition-duration(0.25s);
            -moz-backface-visibility: hidden;
        }

        &:hover {
            img {
                opacity: 0.9;
                -moz-backface-visibility: hidden;
            }
        }
    }

    @media only screen and (max-width: $screen-sm-max) {
        td {
            padding: 0px 10px 0px 10px;

            &:first-child {
                padding-left: 0px;
            }

            &:last-child {
                padding-right: 0px;
            }
        }
    }

    @media only screen and (max-width: $screen-xs-max) {
        td {
            width: 100% !important;
            padding: 0px 5px 0px 5px;

            &:first-child {
                padding-left: 0px;
            }

            &:last-child {
                padding-right: 0px;
            }
        }
    }

    @media only screen and (max-width: $screen-xs) {
        td {
            float: left;
            clear: both;
            width: 100% !important;
            padding: 0px 0px 15px 0px;

            &:last-child {
                padding-bottom: 0px;
            }
        }
    }
}

* + table.responsive {
    margin-top: 0px;
}





table.responsive2 {
    table-layout: fixed;
    border-collapse: collapse;
    margin-bottom: 10px;
    float: left;
    width: 100%;
    clear: both;
    word-wrap: break-word;

    thead {
        @media only screen and (max-width: $screen-xs-max) {
            display: none;
        }
    }

    td {
        padding: 0px 15px 0px 15px;

        &:first-child {
            padding-left: 0px;
        }

        &:last-child {
            padding-right: 0px;
        }
    }

    img {
        display: inline-block;
        width: auto;
        height: auto;
        max-width: 100%;
        margin: 0px auto;
        padding: 0px;
        border: none;
    }

    a {
        img {
            @include transition-duration(0.25s);
            -moz-backface-visibility: hidden;
        }

        &:hover {
            img {
                opacity: 0.9;
                -moz-backface-visibility: hidden;
            }
        }
    }

    // @media only screen and (max-width: $screen-xs-max) {
    //     td {
    //         padding: 0px 10px 0px 10px;

    //         &:first-child {
    //             padding-left: 0px;
    //         }

    //         &:last-child {
    //             padding-right: 0px;
    //         }
    //     }
    // }

    // @media only screen and (max-width: $screen-xs-max) {
    //     td {
    //         // width: 100% !important;
    //         padding: 0px 5px 0px 5px;

    //         &:first-child {
    //             padding-left: 0px;
    //         }

    //         &:last-child {
    //             padding-right: 0px;
    //         }
    //     }
    // }

    // @media only screen and (max-width: $screen-xs - 1px) {
    @media only screen and (max-width: $screen-xs-max) {
        td {
            float: left;
            clear: both;
            width: 100% !important;
            padding: 0px 0px 15px 0px;

            &:last-child {
                padding-bottom: 0px;
            }
        }
    }
}

* + table.responsive2 {
    margin-top: 0px;
}