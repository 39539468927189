#form-cart {
    float: left;
    clear: both;
    width: 100%;
    
    @media (max-width: $screen-sm-max) {
        margin-top: 70px;
    }
}
.cart-full {
    position: relative;
    // float: left;
    clear: both;
    width: 100%;
    // background-color: #2B002D;
    .cart-box-address {
        position: absolute;
        right: 17px;
        top: 4px;
        h3 {
            float: right;
            clear: both;
            height: 14px;
            line-height: 15px;
            padding-right: 10px;
            font-size: 10px;
            font-weight: normal;
            text-align: right;
            text-transform: uppercase;
            color: $colorWhite;
        }
        .box-content {
            float: right;
            clear: both;
            margin: 12px 0 0;
            line-height: 17px;
            color: $colorWhite;
        }
    }
    .cart-items {
        // float: left;
        clear: both;
        // width: 545px;
        
        padding: 29px 0 0 7px;
        
        @media (max-width: $screen-xs-max) {
            width: 90%;
            padding: 29px 0 0 0;
        }
        
        table {
            border-collapse: collapse;
            text-align: left;
            vertical-align: middle;
            font-size: 15px;
            border: 0;
            margin: 30px 0;
            max-width: 900px;
            // background-color: #071800;
            table-layout: auto;
            float: none;
            @media (min-width: $screen-sm-min) {
                // display: block;
            }      
            
            tr {
                margin: 0;
                padding: 0;
                border: 0;
                th {
                    padding: 0 0 9px 15px;
                    font-weight: normal;
                    vertical-align: middle;
                    border-bottom: none;
                }
            }
            
            thead {
                @media (max-width: $screen-xs-min - 1px) {
                    display: none;
                }
            }
            
            thead tr {
                background-image: url(../simg/table-row-separator.gif);
                background-repeat: repeat-x;
                background-position: 0 bottom;
                &:first-child th:first-child {
                    text-indent: 26px;
                }
            }
            tbody tr {
                
                @media (max-width: $screen-xs-min - 1px) {
                    background-image: url(../simg/table-row-separator.gif);
                    background-repeat: repeat-x;
                    background-position: 0 12px;
                }
                
                td {
                    padding: 37px 0 0;
                    border: 0;
                    font-weight: normal;
                    vertical-align: middle;
                    
                    @media (max-width: $screen-xs-max) {
                        text-align: center !important;
                        * {
                            float: none !important;
                            margin: 0 auto;
                        }
                    }
                    
                    &:first-child {
                        padding-top: 20px;
                    }
                }
                .product-cart-mainphoto {
                    // width: 90px;
                    padding-left: 1px;
                    a {
                        // float: left;
                        // width: 76px;
                        // height: 66px;
                        // line-height: 66px;
                        vertical-align: middle;
                        text-align: center;
                        // background-color: $color-2;
                        display: block;
                        margin: 0 auto;
                        img {
                            width: 120px;
                            display: block;
                            margin: 0 auto;
                            // max-height: 66px;
                            vertical-align: middle;
                        }
                    }
                }
                .product-cart-product {
                    // width: 185px;
                    padding-left: 25px;
                    font-size: 15px;
                    font-weight: 700;
                    line-height: 14px;
                    a {
                        display: inline-block;
                        padding-bottom: 5px;
                    }
                    span {
                        font-size: 12px;
                    }
                    
                }
                .product-cart-qty {
                    // width: 70px;
                    padding-top: 35px;
                    padding-left: 11px;
                    input {
                        width: 24px;
                        height: 24px;
                        border: 1px solid $colorWhite;
                        font-size: 15px;
                        text-align: center;
                        background-color: transparent;
                        color: $colorWhite;
                    }
                }
                .product-cart-price {
                    // width: 60px;
                    padding-left: 5px;
                }
                .product-cart-remarks {
                    // width: 56px;
                    padding-top: 35px;
                    padding-left: 11px;
                    .icn {
                        float: left;
                        // width: 33px;
                        // height: 29px;
                        padding: 3px 1px 0;
                        line-height: 15px;
                        text-align: center;
                        font-size: 12px;
                        text-transform: uppercase;
                        // background-image: url(../simg/icn-remarks-off.png);
                        // background-repeat: no-repeat;
                        // background-position: 0 0;
                        color: $colorWhite;
                        &.on {
                            color: $colorSec;
                            // background-image: url(../simg/icn-remarks-on.png);
                        }
                    }
                }
                .product-cart-value {
                    // width: 90px;
                    padding-left: 15px;
                }
                .product-cart-remove {
                    // width: 24px;
                    padding-left: 3px;
                    padding-top: 35px;
                    a {
                        display: block;
                        float: left;
                        // width: 24px;
                        // height: 22px;
                        color: $colorSec;
                        // background-image: url(../simg/btn-remove.png);
                        // background-repeat: no-repeat;
                        // background-position: 0 0;
                    }
                }
                &.product-cart-remarks td {
                    padding-top: 6px;
                    &:first-child {
                        padding-top: 6px;
                    }
                    textarea {
                        width: 525px;
                        height: 43px;
                        margin: 0 0 0 1px;
                        padding: 4px 7px;
                        border: 1px solid $color-10;
                        max-width: 525px;
                        min-width: 525px;
                        font-family: Arial, Helvetica, sans-serif;
                        font-size: 12px;
                        background-color: transparent;
                        color: $color-10;
                    }
                }
            }
            tfoot tr td {
                height: 20px;
                background-image: url(../simg/table-row-separator.gif);
                background-repeat: repeat-x;
                background-position: 0 bottom;
                border-top: none;
            }
        }
    }
    .form-submit {
        // float: left;
        clear: both;
        // width: 547px;
        position: relative;
        @media (max-width: $screen-xs-max) {
            width: 100%;
        }
        
        margin: 11px 0 0 0;
        input {
            // float: right;
            // width: 94px;
            // height: 22px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            background-color: $colorSec;
            border-radius: 50%;
            width: 70px;
            height: 70px;
            font-size: 15px;
            padding: 5px;
            line-height: 15px;
            text-align: center;
            font-weight: 700;
            // background-image: url(../simg/arrow-dot-gray-right.png);
            // background-repeat: no-repeat;
            // background-position: 62px 8px;
            // background-color: $color-4;
            color: $colorWhite;
            transition-property: background-color;
            transition-duration: .5s;
            outline-color: transparent;
            &:hover {
                background-color: $colorSecLight;
            }
        }
    }
    h2.label {
        font-weight: normal;
        background-color: $color-4;
        color: $color-2;
    }
}