.IdFooter {
    .menu {
        float: left;
        clear: both;
        width: 100%;
        
        margin: 37px 0 0;
        @media (max-width: $screen-sm-max) {
            margin: 20px 0;
        }
        
        text-align: center;
        ul {
            display: block;
            float: none;
            clear: both;
            width: 100%;
            margin: 0 auto;
            list-style-type: none;
            list-style-position: inside;
            text-align: center;
            li {
                display: inline-block;
                width: auto;
                float: none;
                height: 9px;
                line-height: 15px;
                margin: 0;
                padding: 0;
                overflow: visible;
                white-space: nowrap;
                
                @media (max-width: $screen-sm-max) {
                    margin-left: 0;
                    margin-right: 0;
                    padding-left: 16px;
                    padding-right: 16px;
                    width: 135px;
                    text-align: center;
                }
                
                a {
                    display: block;
                    float: left;
                    margin-top: -3px;
                    font-size: 10px;
                    text-transform: uppercase;
                    color: $color-2;
                    
                    @media (max-width: $screen-sm-max) {
                        text-align: center;
                        float: none;
                        line-height: 4;
                    }
                    
                    &:hover {
                        text-decoration: underline;
                    }
                    &.active {
                        text-decoration: underline;
                    }
                }
                &.active a {
                    text-decoration: underline;
                }
            }
            li+li {
                margin-left: 13px;
                padding-left: 16px;
                border-left: 1px solid $color-2;
                @media (max-width: $screen-sm-max) {
                    border-left: none;
                    margin-left: 0;
                    margin-right: 0;
                    padding-right: 16px;
                }
            }
        }
    }
    .box-copyrights {
        float: left;
        clear: both;
        width: 100%;
        margin: 0;
        
        padding: 0 0 0;
        @media (max-width: $screen-sm-max) {
            padding: 0 0;
        }
        
        // text-align: center;
        ul {
            display: block;
            float: left;
            clear: both;
            width: 100%;
            margin: 0;
            list-style-type: none;
            list-style-position: inside;
            li {
                display: inline-block;
                float: none;
                width: auto;
                height: 9px;
                line-height: 9px;
                margin: 0;
                padding: 0;
                //font-size: 10px;
                text-transform: uppercase;
                overflow: visible;
                
                white-space: nowrap;
                
                @media (max-width: $screen-xs-max) {
                    white-space: normal;
                }
                
                color: $color-2;
                a {
                    color: $color-2;
                }
            }
            li+li {
                margin-left: 13px;
                padding-left: 16px;
                border-left: 1px solid $color-2;
            }
        }
    }
}

// ------------------------------------------------------------------------------------------------

.IdFooter .Footer {
    @include clearfix;
    @media (min-width: $grid-float-breakpoint) {
        display: flex;
        align-content: stretch;
        align-items: stretch;
    }
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    padding-top: 50px;
    padding-bottom: 20px;
}

.IdFooter .FooterMenuSocial {
    flex: 10;
    
    .SocialMenuWrp {
        padding-top: 50px;
        .SocialMenu {
            position: absolute;
            top: 0;
            left: 35px;
            right: auto;
            > ul {
                width: 100%;
                > li {
                    
                }
            }
        }
    }
}

// .IdFooter .FooterMenu {
//     flex: 25;     
// }

.IdFooter .FooterContact {
    flex: 12;
    padding-left: 40px;
    @media (max-width: $grid-float-breakpoint-max) {
        display: block;
        padding-left: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.IdFooter .FooterInfo {
    flex: 12;
    
    .box-copyrights {
        
        @media (max-width: $grid-float-breakpoint-max) {
            display: block;
            padding-left: 30px;
            padding-top: 30px;
            padding-bottom: 40px;
        }
    }
}