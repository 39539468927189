.product-galleries {
    position: relative;
}
.product-gallery {
    float: left;
    clear: both;
    
    width: 431px;
    
    @media (max-width: 457px) {  
        max-width: 100% !important;
        overflow-x: visible;
        overflow-y: hidden;
    }
    
    height: 390px;
    margin: 20px 0 0;
    .product-gallery-main {
        position: relative;
        float: left;
        clear: both;
        
        width: 431px;
        // +++ max-width: 100% !important;
        
        height: 323px;
        margin: 0;
        padding: 0;
        overflow: visible;
        background-color: $color-2;
        
        // +++ .bx-wrapper {
        // +++   max-width: 100% !important;
            
        // +++   .bx-window {
        // +++     max-width: 100% !important;
        // +++   }
        // +++ }
        
        &.sampler-photos-main {
            display: none;
        }
        ul {
            display: block;
            float: left;
            clear: both;
            list-style-type: none;
            list-style-position: inside;
            height: 323px;
            overflow: hidden;
            li {
                display: block;
                float: left;
                
                // width: 411px;
                width: 411px;
                
                // +++ max-width: 100% !important;
                
                // height: 303px;
                height: 323px;
                
                padding: 10px;
                
                margin: 0 20px; // some javascript sets li width to 411px so compensate with margin
                
                line-height: 303px;
                vertical-align: middle;
                text-align: center;
                background-color: $color-2;
                a {
                    display: block;
                    float: left;
                    clear: both;
                    width: 100%;
                    height: 100%;
                    img {
                        vertical-align: middle;
                        max-width: 100%;
                        // +++ max-width: 100% !important;
                    }
                }
            }
        }
        .gallery-photo-prev {
            position: absolute;
            display: block;
            top: 140px;
            left: -8px;
            width: 39px;
            height: 39px;
            background-image: url(../simg/product-arrow-left2.png);
            background-repeat: no-repeat;
            background-position: 0 0;
        }
        .gallery-photo-next {
            position: absolute;
            display: block;
            top: 140px;
            right: -8px;
            width: 39px;
            height: 39px;
            background-image: url(../simg/product-arrow-right2.png);
            background-repeat: no-repeat;
            background-position: 0 0;
        }
    }
    
    
    .product-gallery-miniatures {
        position: relative;
        float: left;
        clear: both;
        width: 100%;
        height: 66px;
        margin: 1px 0 0;
        padding: 0;
        background-color: $color-2;
        text-align: center;
        
        
        @media (max-width: $screen-xs-max) {
            // +++ display: none;
        }
        
        .product-gallery-miniatures-items {
            clear: both;
            width: 350px;
            height: 66px;
            margin: 0 auto;
            overflow: hidden;
        }
        ul {
            display: block;
            float: left;
            clear: both;
            list-style-type: none;
            list-style-position: inside;
            li {
                display: block;
                float: left;
                width: auto!important;
                
                // height: 56px;
                height: 66px;
                
                padding: 5px 10px;
                line-height: 56px;
                vertical-align: middle;
                text-align: center;
                background-color: $color-2;
                opacity: .5;
                &.pager-active {
                    opacity: 1;
                }
                a {
                    float: left;
                    line-height: 56px;
                    vertical-align: middle;
                    img {
                        vertical-align: middle;
                    }
                }
            }
        }
        .bx-window {
            width: 431px!important;
            // @media (max-width: $screen-xs-max) {
            //   max-width: 100% !important;
            // }
        }
        .gallery-photo-prev {
            position: absolute;
            display: block;
            top: 17px;
            left: -9px;
            width: 39px;
            height: 39px;
            background-image: url(../simg/product-arrow-left2.png);
            background-repeat: no-repeat;
            background-position: 0 0;
        }
        .gallery-photo-next {
            position: absolute;
            display: block;
            top: 17px;
            right: -9px;
            width: 39px;
            height: 39px;
            background-image: url(../simg/product-arrow-right2.png);
            background-repeat: no-repeat;
            background-position: 0 0;
        }
    }
}

.product-galleries-with-samplers .product-gallery {
    height: 500px;
}