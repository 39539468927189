.galleries {
    float: left;
    clear: both;
    width: 100%;
    .gallery {
        float: left;
        clear: both;
        width: 100%;
        margin-bottom: 20px;
        &.link {
            clear: none;
            width: 135px;
            margin: 0 0 20px 0;
            .gallery-photos {
                float: left;
                clear: both;
                width: 100%;
                margin: 0;
            }
            li {
                float: left;
                width: 100%;
                text-align: center;
                vertical-align: middle;
                line-height: 100px;
                background-color: $color-2;
            }
            img {
                max-width: 125px;
                max-height: 90px;
                vertical-align: middle;
            }
            h1.label {
                width: 99px;
                float: left;
            }
        }
        .gallery-description {
            float: left;
            width: 245px;
            height: 392px;
            margin: 18px 0 0 18px;
            text-align: justify;
            overflow: auto;
        }
        &.link+.gallery.link {
            margin-left: 15px;
        }
    }
}