#cart-fast {
    position: fixed;
    left: 0;
    z-index: 10000; // over menu
    top: 9px;
    
    // width: 202px;
    width: 221px;
    
    padding: 0 8px 7px 11px;
    // background-image: url(../simg/cart-back.png);
    background-repeat: repeat;
    background-color: $colorSec;
    color: $color-2;
    
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    
    
    
    
    .cart-fast {
        float: left;
    }
    .rollback {
        display: none;
    }
    .cart-label {
        float: left;
        clear: both;
        width: 170px;
        
        // height: 20px;
        height: 24px;
        
        padding: 4px 5px 0 27px;
        font-size: 10px;
        background-image: url(../simg/icn-add-to-cart.png);
        background-repeat: no-repeat;
        background-position: 9px 7px;
        color: $colorWhite;
    }
    .cart-items {
        float: left;
        clear: both;
        width: 100%;
        margin: 6px 0 14px;
        table {
            margin-left: 19px;
            border-collapse: collapse;
            tr td {
                width: 140px;
                font-size: 12px;
                font-weight: normal;
                padding: 0 0 9px 0;
                &:first-child {
                    width: 20px;
                    font-size: 9px;
                }
            }
        }
    }
    .cart-phone {
        float: left;
        clear: both;
        width: 100%;
        height: 26px;
        font-size: 24px;
        font-weight: bold;
        line-height: 28px;
    }
    .cart-go-to-order {
        float: left;
        clear: both;
        width: 100%;
        a {
            float: left;
            
            // width: 147px;
            width: 184px;
            
            // height: 14px;
            height: 22px;
            
            line-height: 15px;
            text-align: center;
            background-color: $colorSec;
            color: $colorWhite;
        }
    }
    .cart-toggler {
        position: absolute;
        bottom: -21px;
        left: 0;
        height: 21px;
        a.btn {
            float: left;
            // padding-right: 25px;
            // background-image: url(../simg/cart-back.png);
            background-repeat: repeat;
            background-color: transparent;
            background-color: $colorSec;
            color: $colorWhite;
            
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            &.cart-rollback {
                display: none;
                .arrow {
                    background-position: right 2px;
                    display: none;
                }
            }
            &.cart-rollover .arrow {
                background-position: right 2px;
                display: none;
            }
        }
    }
    &.loading .cart-fast {
        background-image: url(../simg/ajax-loader.gif);
        background-repeat: no-repeat;
        background-position: 185px 4px;
    }

    &.cart-fast-push-down {
        @media (max-width: $screen-sm-max) {
            top: 54px;
            z-index: 9997; // under menu
        }
    }

}