.product-mark {
    display: none;
    position: absolute;
    // top: 0px;
    top: -54px;
    // left: 0px;
    right: 4px;
    // width: 60px;
    width: 65px;
    // height: 60px;
    height: 65px;
    background-color: $colorSec;
    background-position: 0 0;
    background-repeat: no-repeat;
    border-radius: 50%;
    border: 1px solid $colorBlack;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    
    display: none;
    justify-content: center;
    align-items: center;
    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;
        width: 65px;
        height: 65px;
        color: lighten($colorSec, 3%);
        font-weight: 700;
        font-size: 60px;
        line-height: 60px;
        text-align: center;
        content: "%";
        display: block;
    }
    span {
        background-color: $colorSec;
        position: relative;
        z-index: 1;
    }
}

.mark-1 .product-mark {
    display: flex;
    // background-image: url(../simg/pl/product-mark-new.png);
}
.mark-2 .product-mark {
    display: flex;
    // background-image: url(../simg/pl/product-mark-promo.png);
}
.mark-3 .product-mark {
    display: flex;
    // background-image: url(../simg/pl/product-mark-sale.png);
}

.products-boxes {
    //float: left;
    clear: both;
    @include clearfix;
    // @extend .row;
    // width: 723px;
    @media (max-width: $screen-xs-max) {
        width: 100%;
        text-align: center;
    }
    
    padding-top: 19px;
    // margin: 0;
    .product-box {
        // @extend .col-xs-12, .col-sm-4;
        // position: relative;
        // float: left;
        // width: 221px;
        // height: 305px;
        // margin: 0 20px 27px 0;
        // overflow: visible;
        
        @media (max-width: $screen-xs-max) {
            // float: none;
            // display: inline-block;
            // margin: 0 10px 27px 10px;
        }
        
        .product-box-inner {
            position: relative;
            @include clearfix;
            // background-color: #211111; // TODO
            min-height: 305px;
            max-width: 390px;
            // width: 90%;
            margin: 35px auto 35px auto;
            // margin-bottom: 100px;
            
            .product-mainphoto {
                // position: absolute;
                // top: 0;
                // left: 0;
                
                // width: 211px;
                // width: 221px;
                width: 100%;
                // height: 156px;
                // height: 166px;
                height: auto;
                
                // padding: 5px;
                background-color: $colorBlack;
                overflow: visible;
                a {
                    position: relative;
                    display: block;
                    float: left;
                    clear: both;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    .mainphoto {
                        display: block;
                        // float: left;
                        // width: 211px;
                        // height: 156px;
                        // line-height: 150px;
                        text-align: center;
                        vertical-align: middle;
                        background-color: transparent;
                        // &.mainphoto {
                        //     position: absolute;
                        //     top: 0;
                        //     left: 0;
                        // }
                        img {
                            vertical-align: middle;
                            width: 100%;
                            height: auto;
                        }
                    }
                    &:hover,
                    &:focus {
                        img {
                            opacity: .9;
                        }
                    }
                }
                // .bx-prev {
                //     position: absolute;
                //     left: -14px;
                //     top: 56px;
                //     width: 39px;
                //     height: 39px;
                //     background-image: url(../simg/product-arrow-left2.png);
                //     background-repeat: no-repeat;
                //     background-position: 0 0;
                // }
                // .bx-next {
                //     position: absolute;
                //     right: -14px;
                //     top: 56px;
                //     width: 39px;
                //     height: 39px;
                //     background-image: url(../simg/product-arrow-right2.png);
                //     background-repeat: no-repeat;
                //     background-position: 0 0;
                // }
                // .bx-window a span.mainphoto {
                //     position: relative;
                //     top: auto;
                //     left: auto;
                //     background-color: transparent;
                // }
            }
            .product-price {
                // display: none; //TODO
                @include clearfix;
                position: static;
                float: right;
                margin-left: 10px;
                margin-bottom: 10px;
                top: -7px;
                right: -6px;
                // padding: 2px 13px 3px 15px;
                padding: 8px 0 0 0;
                width: auto;
                font-size: 14px;
                font-weight: bold;
                text-transform: none;
                cursor: default;
                width: 70px;
                height: 70px;
                border-radius: 50%;
                background-color: $colorSec;
                color: $colorBlack;
                
                // &.product-special-price {
                //     white-space: nowrap;
                //     background-color: $color-4;
                //     color: $color-2;
                //     .product-price-placeholder {
                //         display: none;
                //         margin-top: 5px;
                //         font-size: 9px;
                //         text-decoration: line-through;
                        
                //         .product-price-prefix {
                            
                //         }
                        
                //         .product-price-currency {
                //             display: none;
                //         }
                //     }
                // }
                
                .product-price-comment-only {
                    width: 60px;
                    height: 70px;
                    white-space: normal;
                    padding: 12px 0 0 8px;
                    text-align: center;
                    line-height: 14px;
                }
                
                .product-price-placeholder,
                .product-price-special-placeholder {
                    width: 70px;
                    height: 70px;
                    .product-price-prefix,
                    .product-special-price-prefix {
                        display: block;
                        text-align: center;
                        font-size: 9px;
                        font-weight: 400;
                        height: 13px;
                        line-height: 21px;
                        // background-color: #0B7A00;
                    }
                    .product-price-value,
                    .product-special-price-value {
                        display: block;
                        text-align: center;
                        font-size: 20px;
                        font-weight: 700;
                        height: 27px;
                        // background-color: #003390;
                    }
                    .product-price-currency,
                    .product-price-currency {
                        display: block;
                        text-align: center;
                        font-size: 9px;
                        font-weight: 400;
                        height: 13px;
                        line-height: 3px;
                        // background-color: #900073;
                        
                        .product-price-suffix {
                            
                        }
                    }
                }
                
                .product-price-special-placeholder {
                    .product-special-price-label {
                        .product-special-price-prefix {
                            // display: inline;
                        }
                    }
                    .product-special-price-value {
                        
                    }
                    .product-price-currency {
                        .product-price-suffix {
                            
                        }
                    } 
                }
                
                // .product-price-special-placeholder {
                //     float: left;
                //     margin-left: 5px;
                //     font-weight: bold;
                //     line-height: normal;
                //     .product-special-price-label {
                //         margin-top: 1px;
                //         margin-right: 14px;
                //         margin-left: -10px;
                //         font-size: 10px;
                //         font-weight: normal;
                //         text-transform: uppercase;
                //     }
                // }
            }
            .product-description {
                // float: left;
                // clear: both;
                // position: absolute;
                @include clearfix;
                position: relative;
                z-index: 1;
                top: -20px;
                // background-color: #002D6B;
                // top: 189px;
                // width: 221px;
                // width: 90%;
                margin: 0 auto;
                // height: 82px;
                // height: 140px;
                @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                    // height: 166px;
                }
                // overflow: hidden;
                clear: both;
                text-align: left;
                .product-title {
                    // float: left;
                    // clear: both;
                    // background-color: #005B20; //TODO
                    max-width: 75%;
                    min-height: 44px;
                    text-shadow: 2px 2px #000000;
                    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                        // height: 66px;
                    }
                    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                        // height: 88px;
                    }
                    @media (min-width: $screen-lg-min) {
                        // max-width: 84%;
                    }
                    @media (max-width: $screen-xs-min) {
                        // height: 44px;
                    }
                    line-height: 22px;
                    font-size: 22px;
                    font-weight: 400;
                    text-transform: uppercase;
                    a {
                        color: $colorWhite;
                        &:hover,
                        &:focus {
                            color: $colorSec;
                        }
                    }
                }
                .product-long-description {
                    // float: left;
                    // clear: both;
                    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                        // max-width: 84%;
                    }
                    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                        // max-width: 90%;
                    }
                    @media (min-width: $screen-lg-min) {
                        // max-width: 90%;
                    }
                    @media (max-width: $screen-xs-max) {
                        // max-width: 90%;
                    }
                    margin: 11px 0 0 0;
                    line-height: 16px;
                    font-size: 14px;
                    text-transform: uppercase;
                    color: darken($colorWhite, 20%);
                }
            }
            // .product-actions {
            //     position: absolute;
            //     bottom: 0;
            //     left: 0;
            //     width: 221px;
            //     margin: 12px 0 0;
            //     .product-add-to-cart {
            //         position: absolute;
            //         bottom: 0;
            //         left: 0;
            //         float: left;
            //         width: 85px;
            //         height: 22px;
            //         z-index: 2;
            //         .btn.btn-add-to-cart {
            //             float: left;
                        
            //             // width: 49px;
            //             width: 85px;
                        
            //             // height: 14px;
            //             height: 22px;
                        
            //             line-height: 15px;
            //             text-indent: 15px;
            //             background-color: $color-4;
            //             color: $color-2;
            //             background-image: url(../simg/icn-add-to-cart.png);
            //             background-repeat: no-repeat;
            //             background-position: 8px 6px;
            //             &.product-add-to-cart-qty {
            //                 white-space: nowrap;
                            
            //                 // width: 160px;
            //                 width: 178px;
                            
            //                 // height: 14px;
            //                 height: 22px;
                            
            //                 padding-right: 0;
            //                 overflow: hidden;
            //                 span {
            //                     float: left;
            //                     white-space: nowrap;
            //                 }
            //                 input {
            //                     float: left;
            //                     // width: 21px;
            //                     width: 23px;
            //                     // height: 11px;
            //                     height: 15px;
            //                     margin-left: 5px;
            //                     border: 1px solid $color-2;
            //                     font-size: 10px;
            //                     text-align: center;
            //                     background-color: transparent;
            //                     color: $color-2;
            //                 }
            //                 a {
            //                     float: left;
            //                     width: 70px;
            //                     margin-left: 10px;
            //                     text-indent: 0;
            //                     white-space: nowrap;
            //                     background-image: url(../simg/icn-plus.png);
            //                     background-repeat: no-repeat;
                                
            //                     // background-position: 52px 3px;
            //                     background-position: 49px 3px;
                                
            //                     color: $color-2;
            //                     text-align: left;
            //                 }
            //             }
            //         }
            //     }
            //     .product-more {
            //         position: absolute;
            //         bottom: 0;
            //         right: 0;
            //         float: right;
            //         width: 142px;
            //         margin: 0 0 0 -10px;
            //         a.btn.btn-more {
            //             float: left;
                        
            //             // width: 106px;
            //             width: 142px;
            //             // height: 14px;
            //             height: 22px;
                        
            //             line-height: 15px;
            //             text-indent: 42px;
            //             text-align: left;
            //             background-image: url(../simg/arrow-dot-gray-right.png);
            //             background-repeat: no-repeat;
            //             background-position: 107px 8px;
            //             float: right;
            //             color: #fff;
            //             &:hover {
            //                 text-decoration: underline;
            //             }
            //         }
            //     }
            // }
        }
    }
}