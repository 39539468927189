.menu-bottom {
    .navbar-nav {
        >li {
            >a {
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 0;
                padding-right: 0;
                line-height: 1.5;
            }
        }
    }
}

.IdFooter .FooterMenu {
    
    .navbar-nav, .nav {
        display: flex;
        align-items: baseline;
        @media (max-width: $grid-float-breakpoint-max) {
            display: block;
            margin-left: 25px;
        }
        justify-content: flex-start;
        float: none;
        margin: 0;
        padding: 0;
        > li {
            text-align: left;
            // display: inline-block;
            // flex: 1;
            padding: 0 10px 0 20px;
            margin: 0;
            @media (min-width: $grid-float-breakpoint) {
                white-space: nowrap;
            }
            @media (max-width: $grid-float-breakpoint-max) {
                display: block;
                height: auto;
            }
            > a {
                color: $colorPri;
                padding: 0;
                text-decoration: none;
                font-size: 18px;
                @media (max-width: $grid-float-breakpoint-max) {
                    display: block;
                    // padding: 4px 0 4px 0;
                    padding: 8px 0 8px 0;
                }
                &:hover,
                &:focus {
                    text-decoration: none;
                    color: $colorSec;
                    background-color: transparent;
                }
            }
            &.active {
                > a {
                    text-decoration: none;
                    color: $colorSec;
                    background-color: transparent;
                }
            }
            + li {
                position: relative;
                &:before {
                    content: "\00b7";
                    color: $colorSec;
                    // display: inline-block;
                    position: absolute;
                    left: 0;
                    
                    font-size: 40px;
                    line-height: 18px;
                    @media (max-width: $grid-float-breakpoint-max) {
                        display: none;
                    }
                }
            }
        }
    }
    
    .dropdown-menu {
        display: block;
        position: static;
        // left: 5px;
        // top: -20px;
        min-width: 0;
        font-size: 16px;
        box-shadow: none;
        border: 0;
        width: 90%;
        float: none;
        
        padding: 0;
        @media (min-width: $grid-float-breakpoint) {
            padding: 5px 0 0 0;
        }
        
        background-color: transparent;
        @media (max-width: $grid-float-breakpoint-max) {
            display: block;
            margin-left: 15px;
        }
        > li {
            text-align: left;
            margin: 0;
            padding: 0;
            @media (max-width: $grid-float-breakpoint-max) {
                display: block;
            }
            > a {
                font-size: 18px;
                color: $colorPri;
                margin: 0;
                padding: 4px 0 4px 0;
                color: $colorPri;
                text-decoration: none;
                @media (max-width: $grid-float-breakpoint-max) {
                    display: block;
                }
                &:hover,
                &:focus {
                    text-decoration: none;
                    color: $colorSec;
                    background-color: transparent;
                }
            }
            &.active {
                > a {
                    text-decoration: none;
                    color: $colorSec;
                    background-color: transparent;
                }
            }
        }
    }
}

.IdFooter .FooterMenuSocial .SocialMenuWrp .SocialMenu {
    z-index: 9998;
}