#single-product {
    float: left;
    clear: both;
    width: 100%;
    
    
    
    .ProductTop {
        @include clearfix;
        // background-color: #002513;
        // margin-bottom: 500px;
        position: relative;
    }
    
    .ProductTitle {
        @media (min-width: $screen-lg-min) {
            position: absolute;
            z-index: 1;
            top: 25px;
            left: 43%;
        }
        @media (max-width: $grid-float-breakpoint-max) {
            text-align: center;
        }
    }
    
    .ProductSliderCol {
        @extend .col-xs-12, .col-sm-12, .col-md-9, .col-lg-7;

        position: relative;

        @media (max-width: $screen-sm-max) {
            max-width: 582px;
            margin: 0 auto;
            float: none;
        }
        
        @media (min-width: $screen-md-min) {
            // max-width: 582px;
            
            // width: 55.8%; //726 55.8
            width: 68.045%; //726 55.8
            padding-right: 69.83px;
        }
        
        @media (min-width: $screen-lg-min) {
            width: 55.8%; //726 55.8
            // width: 51.295%;
            // width: 51.295%;
            padding-right: 128.4px;
        }
        
        // background-color: #390000;

    }
    
    .ProductDataCol {
        @extend .col-xs-12, .col-sm-6, .col-md-3, .col-lg-2;

        // @media (min-width: $screen-sm-min) {
            // width: 23.4%;  //290 22.4
            padding-top: 30px;
        // }

        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            // width: 23.4%;  //290 22.4
            width: 31.955%;
            padding-top: 0;
        }
        
        @media (min-width: $screen-lg-min) {
            width: 23.4%;  //290 22.4
            padding-top: 110px;
        }

        // background-color: #00133B;

    }
    
    .ProductActionsCol {
        @extend .col-xs-12, .col-sm-6, .col-md-12, .col-lg-3;

        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            // width: 20.8%; //284 21.8
            padding-top: 30px;
        }
        @media (min-width: $screen-lg-min) {
            width: 20.8%; //284 21.8
            padding-top: 110px;
        }

        // background-color: #363B00;
    }
    
    .ProductNavigation {
        position: absolute;
        z-index: 1;
        bottom: 0;
        width: 100%;
        margin-left: -15px;
        padding: 0 15px;
        
        @media (min-width: $screen-md-min) {
            padding: 0 20px;
            width: 93%;
        }
        
        @media (min-width: $screen-lg-min) {
            padding: 0 20px;
            width: 84%;
        }
        
        @media (max-width: $screen-xxs-max) {
            bottom: -30px;
        }
        
        a {
            text-transform: uppercase;
            color: $colorSec;
            &:hover {
                color: $colorSecLight;
            }
        }
        .product-next {
            float: right;
        }
    }
    
    .ProductSlider {
        position: relative;
        width: 100%;
        // max-width: 582px;
        // max-height: 582px;
        height: 1px;
        // padding-bottom: 83.695%;
        padding-bottom: 100%;
        background-color: $colorWhite;
        // @media (min-width: $screen-xs) {
            border-radius: 50%;
        // }
        background-image: url('../simg/bg/bg-slider.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // overflow: hidden;
        
        .product-price-wrp {
            // display: none !important; //TODO
            position: absolute;
            z-index: 100;
            background-color: $colorSec;
            background-position: 0 0;
            background-repeat: no-repeat;
            border-radius: 50%;
            width: 28.413%;
            height: 28.413%;
            top: 12%;
            right: -12%;
            pointer-events: none;
            @media (max-width: $grid-float-breakpoint-max) {
                top: 0;
                right: -2%;
            }
            @media (min-width: $screen-lg-min) {
                right: -16%;
            }
            
            
            .product-price {
                // float: left;
                // clear: both;
                width: 100%;
                height: 100%;
                margin: 0 0 0;
                // font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
                color: $colorBlack;
                .product-price-placeholder {
                    // float: left;
                    // clear: both;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    font-weight: 400;
                    .product-price-prefix {
                        display: block;
                        font-size: 22px;
                        font-weight: 400;
                        line-height: 22px;
                    }
                    .product-price-value {
                        display: block;
                        font-size: 30px;
                        line-height: 30px;
                        font-size: 7vw;
                        line-height: 7vw;
                        @media (min-width: $screen-sm-min) {
                            font-size: 4vw;
                            line-height: 4vw;
                        }
                        @media (min-width: $screen-lg-min) {
                            font-size: 50px;
                            line-height: 50px;
                        }
                        font-weight: 700;
                    }
                    .product-price-currency {
                        display: block;
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 24px;
                    }
                }
                .product-price-special-placeholder {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    font-weight: 400;
                    .product-special-price-prefix {
                        display: block;
                        font-size: 22px;
                        font-weight: 400;
                        line-height: 22px;
                    }
                    .product-special-price-value {
                        display: block;
                        font-size: 30px;
                        line-height: 30px;
                        font-size: 7vw;
                        line-height: 7vw;
                        @media (min-width: $screen-sm-min) {
                            font-size: 4vw;
                            line-height: 4vw;
                        }
                        @media (min-width: $screen-lg-min) {
                            font-size: 50px;
                            line-height: 50px;
                        }
                        font-weight: 700;
                    }
                    .product-special-price-currency {
                        display: block;
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 24px;
                    }
                }
                .product-price-comment {
                    display: block;
                    font-size: 30px;
                    line-height: 30px;
                    font-size: 3vw;
                    line-height: 3vw;
                    @media (min-width: $screen-md-min) {
                        font-size: 2vw;
                        line-height: 2vw;
                    }
                    @media (min-width: $screen-lg-min) {
                        font-size: 30px;
                        line-height: 30px;
                    }
                    font-weight: 700;
                    text-align: center;
                    display: flex;
                    justify-content: cente;
                    align-items: center;
                    width: 90%;
                    margin-left: 5%;
                    height: 100%;
                    span {
                        cursor: default;
                    }
                }
                // .product-price-placeholder > span {
                //     display: inline;
                //     margin: 0 auto;
                //     padding: 1px 13px 3px 15px;
                //     text-transform: none;
                //     font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
                //     font-size: 20px;
                //     font-weight: bold;
                //     cursor: default;
                // }
                // .product-price-special-placeholder > span {
                //     display: inline;
                //     margin: 0 auto;
                //     padding: 1px 13px 3px 15px;
                //     text-transform: none;
                //     font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
                //     font-size: 15px;
                //     font-weight: bold;
                //     cursor: default;
                // }
                &.product-special-price .product-price-placeholder  {
                    
                    display: none;
                    
                }
            }
            
            
        }
        
        .product-mark {
            // display: none !important; // TODO
            width: 16.394%;
            height: 16.394%;
            font-size: 1.7vw;
            border: 0;
            @media (min-width: $screen-sm-min) {
                font-size: 12px;
            }
            left: 7%;
            top: 7%;
            overflow: hidden;
            z-index: 100;
            &:after {
                width: 105px;
                height: 105px;
                font-size: 105px;
                line-height: 105px;
            }
        }
    }
    
    .ProductActions {
        // background-color: #626300;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        @media (min-width: $screen-sm-min)  {
            justify-content: flex-end;
            float: right;
            max-width: 240px;
        }
        @media (max-width: $grid-float-breakpoint-max) {
            margin-top: 30px;
        }
        > * {
            flex: 0 0 70px;
            margin: 0 0 10px 10px;
            @media (max-width: $screen-sm-max) {
                margin: 5px 5px 5px 5px;
            }
        }
    }
    
    

    
    
    
    
    
    
    
    
    // .product-left {
        
    //     @include clearfix;
        
    //     float: left;
    //     clear: left;
    //     width: 450px;
        
    //     max-width: 100%;
        
    //     @media (max-width: $screen-xs-max) {
    //         float: none;
    //         margin: 10px auto 30px auto;
    //     }
        
    //     h1.label {
    //         // width: 405px;
    //         width: 430px;
    //         max-width: 100%;
            
    //         padding-right: 8px;
    //         white-space: normal;
    //         a {
    //             color: inherit;
    //         }
    //     }
    // }
    // .product-right {
    //     position: relative;
    //     float: left;
    //     clear: right;
    //     width: 260px;
        
    //     @media (max-width: $screen-xs-max) {
    //         @include clearfix;
    //         float: none;
    //         margin: 10px auto 30px auto;
    //     }
        
    //     .product-navigation {
    //         float: left;
    //         clear: both;
    //         width: 100%;
    //         height: 22px;
    //         .btn {
    //             float: left;
                
    //             // width: 224px;
    //             width: 260px;
                
    //             cursor: default;
                
                
    //             // height: 14px;
    //             height: 22px;
                
    //             line-height: 15px;
    //         }
    //         .product-prev {
    //             display: block;
    //             float: left;
    //             color: $color-2;
    //             margin: 0 0 0 -7px;
    //             padding: 0 0 0 17px;
    //             text-align: left;
    //             background-image: url(../simg/arrow-thick-white-left.png);
    //             background-repeat: no-repeat;
    //             background-position: left 2px;
    //         }
    //         .product-next {
    //             display: block;
    //             float: right;
    //             color: $color-2;
    //             text-align: right;
    //             margin: 0 -7px 0 0;
    //             padding: 0 17px 0 0;
    //             background-image: url(../simg/arrow-thick-white-right.png);
    //             background-repeat: no-repeat;
    //             background-position: right 2px;
    //         }
    //     }
    //     .product-base-informations {
    //         position: relative;
    //         float: left;
    //         clear: both;
    //         width: 100%;
    //         margin: 20px 0 0;
    //         .product-title {
    //             float: left;
    //             clear: both;
    //             width: 100%;
    //             h2 {
    //                 margin: 1px 0 0;
    //                 font-size: 14px;
    //                 font-weight: 700;
    //             }
    //             h3 {
    //                 margin: 1px 0 0;
    //                 font-size: 11px;
    //                 font-weight: normal;
    //                 font-style: italic;
    //             }
    //             h4 {
    //                 margin: 1px 0 0;
    //                 font-size: 11px;
    //                 font-weight: normal;
    //                 font-style: italic;
    //             }
    //         }
    //         // .product-price {
    //         //     float: left;
    //         //     clear: both;
    //         //     width: 100%;
    //         //     margin: 37px 0 0;
    //         //     font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
    //         //     .product-price-placeholder {
    //         //         float: left;
    //         //         clear: both;
    //         //         width: 100%;
    //         //         text-align: center;
    //         //     }
    //         //     .product-price-special-placeholder {
    //         //         float: left;
    //         //         clear: both;
    //         //         width: 100%;
    //         //         text-align: center;
    //         //         margin-top: 10px;
    //         //     }
    //         //     .product-price-comment {
    //         //         float: left;
    //         //         clear: both;
    //         //         width: 100%;
    //         //         text-align: center;
    //         //         margin-top: 10px;
    //         //         span {
    //         //             cursor: default;
    //         //         }
    //         //     }
    //         //     .product-price-placeholder > span {
    //         //         display: inline;
    //         //         margin: 0 auto;
    //         //         padding: 1px 13px 3px 15px;
    //         //         text-transform: none;
    //         //         font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
    //         //         font-size: 20px;
    //         //         font-weight: bold;
    //         //         cursor: default;
    //         //     }
    //         //     .product-price-special-placeholder > span {
    //         //         display: inline;
    //         //         margin: 0 auto;
    //         //         padding: 1px 13px 3px 15px;
    //         //         text-transform: none;
    //         //         font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
    //         //         font-size: 15px;
    //         //         font-weight: bold;
    //         //         cursor: default;
    //         //     }
    //         //     &.product-special-price .product-price-placeholder > span {
    //         //         border: 0;
    //         //         font-size: 15px;
    //         //         text-decoration: line-through;
    //         //         background-color: transparent;
    //         //         color: $color-4;
    //         //     }
    //         // }
    //         .product-menu {
    //             float: left;
    //             clear: both;
    //             margin: 20px 0 0 83px;
    //             font-size: 10px;
    //             text-transform: uppercase;
    //             img {
    //                 margin: 0 11px 0 0;
    //             }
    //         }
    //     }
    //     .product-actions {
    //         position: absolute;
    //         top: 282px;
            
    //         @media (max-width: $screen-xs-max) {
    //             position: relative;
    //             top: 20px;
    //         }
            
    //         left: 0;
    //         width: 100%;
    //         .product-add-to-compare {
    //             display: none;
    //             float: left;
    //             clear: both;
    //             width: 208px;
    //             height: 14px;
    //             padding-left: 34px;
    //             line-height: 15px;
    //             background-image: url(../simg/icn-add-to-compare.png);
    //             background-repeat: no-repeat;
    //             background-position: 9px 7px;
    //             background-color: $color-2;
    //             color: $color-0;
    //             a {
    //                 display: block;
    //                 float: left;
    //                 width: 100%;
    //             }
    //         }
    //         .product-add-to-cart {
    //             float: left;
    //             clear: both;
                
    //             // width: 208px;
    //             width: 260px;
                
    //             // height: 14px;
    //             height: 22px;
                
    //             line-height: 15px;
    //             margin-top: 9px;
    //             padding-left: 34px;
    //             background-image: url(../simg/icn-add-to-cart.png);
    //             background-repeat: no-repeat;
    //             background-position: 9px 7px;
    //             background-color: $color-4;
    //             color: $color-2;
                
    //             text-align: left;
                
    //             div {
    //                 float: left;
    //                 clear: both;
    //                 width: 100%;
    //             }
    //             .product-add-to-cart-qty {
    //                 white-space: nowrap;
    //                 width: 220px;
    //                 span {
    //                     float: left;
    //                     white-space: nowrap;
    //                 }
    //                 input {
    //                     float: left;
    //                     // width: 21px;
    //                     width: 23px;
    //                     // height: 11px;
    //                     height: 15px;
    //                     margin-left: 5px;
    //                     border: 1px solid $color-2;
    //                     font-size: 10px;
    //                     text-align: center;
    //                     background-color: transparent;
    //                     color: $color-2;
    //                 }
    //                 a {
    //                     float: left;
    //                     width: 145px;
    //                     margin-left: 10px;
    //                     white-space: nowrap;
    //                     background-image: url(../simg/icn-plus.png);
    //                     background-repeat: no-repeat;
    //                     background-position: 135px 3px;
    //                     color: $color-2;
    //                 }
    //             }
    //             a.show-cart-qty {
    //                 position: absolute;
    //                 top: 0;
    //                 left: 0;
                    
    //                 // width: 220px;
    //                 width: 250px;
                    
    //                 height: 18px;
    //                 // height: 26px;
                    
    //                 padding-top: 4px;
    //                 padding-left: 34px;
    //                 background-image: url(../simg/arrow-thick-white-right.png);
    //                 background-repeat: no-repeat;
    //                 background-position: 242px 7px;
    //                 color: $color-2;
    //                 text-align: left;
    //             }
    //         }
    //         // .product-ask {
    //         //     float: left;
    //         //     clear: both;
                
    //         //     // width: 216px;
    //         //     width: 260px;
    //         //     // height: 14px;
    //         //     height: 22px;
                
    //         //     line-height: 15px;
    //         //     margin-top: 9px;
    //         //     padding-right: 10px;
    //         //     padding-left: 34px;
    //         //     color: $color-2;
    //         //     text-align: left;
                
    //         //     &.opened {
    //         //         // height: 102px;
    //         //         height: 110px;
    //         //         cursor: default;
    //         //         &.loading {
    //         //             background-image: url(../simg/ajax-loader.gif);
    //         //             background-repeat: no-repeat;
    //         //             background-position: center center;
    //         //             .product-ask-content {
    //         //                 display: none;
    //         //                 position: relative;
    //         //                 float: left;
    //         //                 clear: both;
    //         //                 width: 100%;
    //         //                 background: none repeat scroll 0 0 $color-0;
    //         //                 z-index: 2;
    //         //             }
    //         //         }
    //         //         .product-ask-content {
    //         //             display: block;
    //         //         }
    //         //         a.product-ask-show {
    //         //             cursor: default;
    //         //             span {
    //         //                 display: none;
    //         //                 &.product-ask-decoration {
    //         //                     position: absolute;
    //         //                     display: inline;
    //         //                     top: 4px;
    //         //                     left: 12px;
    //         //                     font-weight: bold;
    //         //                     text-decoration: none;
    //         //                     color: $color-2;
    //         //                 }
    //         //             }
    //         //         }
    //         //     }
    //         //     .sent-info {
    //         //         display: none;
    //         //     }
    //         //     .product-ask-content {
    //         //         display: none;
    //         //         position: relative;
    //         //         float: left;
    //         //         clear: both;
    //         //         width: 100%;
    //         //         background: none repeat scroll 0 0 $color-0;
    //         //         z-index: 2;
    //         //         textarea {
    //         //             float: left;
    //         //             clear: both;
    //         //             // width: 210px;
    //         //             width: 216px;
                        
    //         //             // height: 55px;
    //         //             height: 61px;
                        
    //         //             margin: 4px 0 0;
    //         //             padding: 3px;
    //         //             border: 1px solid $color-2;
    //         //             resize: none;
    //         //             font-family: Arial, Helvetica, sans-serif;
    //         //             font-size: 12px;
    //         //             text-align: left;
    //         //             background-color: $color-0;
    //         //             color: $color-2;
    //         //             &.i-error {
    //         //                 border-color: $color-7;
    //         //             }
    //         //         }
    //         //         input {
    //         //             float: left;
    //         //             clear: left;
    //         //             // width: 100px;
    //         //             width: 106px;
    //         //             margin: 8px 0 0;
    //         //             padding: 3px;
    //         //             border: 1px solid $color-2;
    //         //             font-family: Arial, Helvetica, sans-serif;
    //         //             font-size: 12px;
    //         //             background-color: $color-0;
    //         //             color: $color-2;
    //         //             &.i-error {
    //         //                 border-color: $color-7;
    //         //             }
    //         //         }
    //         //         a {
    //         //             float: right;
    //         //             clear: right;
    //         //             margin: 13px 0 0;
    //         //             padding: 0 3px;
    //         //             white-space: nowrap;
    //         //             color: $color-2;
    //         //         }
    //         //     }
    //         //     &.sent .sent-info {
    //         //         display: block;
    //         //         background: none repeat scroll 0 0 $color-6;
    //         //         height: 75px;
    //         //         left: 25px;
    //         //         padding-top: 30px;
    //         //         padding-right: 5px;
    //         //         position: absolute;
    //         //         text-align: center;
    //         //         top: 0;
    //         //         width: 225px;
    //         //         z-index: 5;
    //         //     }
    //         //     a.product-ask-show {
    //         //         position: absolute;
    //         //         top: 0;
    //         //         left: 0;
                    
    //         //         // width: 220px;
    //         //         width: 250px;
                    
    //         //         height: 18px;
    //         //         padding-top: 4px;
    //         //         padding-left: 34px;
    //         //         background-image: url(../simg/arrow-thick-white-right.png);
    //         //         background-repeat: no-repeat;
    //         //         background-position: 242px 7px;
    //         //         color: $color-2;
    //         //         span.product-ask-decoration {
    //         //             position: absolute;
    //         //             display: inline;
    //         //             top: 4px;
    //         //             left: 12px;
    //         //             font-weight: bold;
    //         //             text-decoration: none;
    //         //             color: $color-2;
    //         //         }
    //         //     }
    //         // }
    //     }
    // }
    .product-bottom {
        float: left;
        clear: both;
        padding: 20px 0 0 9px;
        
        // width: 685px;
        @media (max-width: $screen-xs-max) {
            width: 100%;
            p {
                margin-right: 10px;
            }
        }
        
        line-height: 16px;
        p+p {
            margin-top: 15px;
        }
        .product-sizes {
            float: left;
            clear: both;
            width: 100%;
            margin: 3px 0 0;
            span:last-child .size-separator {
                display: none;
            }
        }
        .product-size-comment {
            float: left;
            clear: both;
            width: 100%;
        }
        .product-availability {
            float: left;
            clear: both;
            width: 100%;
            margin: 28px 0 0;
        }
        .product-availability-comment {
            float: left;
            clear: both;
            width: 100%;
        }
        .product-description {
            float: left;
            clear: both;
            width: 100%;
            margin: 28px 0 0;
            text-align: justify;
            line-height: normal;
        }
        .product-variants {
            float: left;
            clear: both;
            width: 100%;
            margin: 50px 0 0;
            @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
                width: 90%;
                margin: 50px 2% 0;
            }
        }
    }
    .product-variants {
        @include clearfix;
        float: left;
        clear: both;
        width: 100%;
        margin: 50px 0 0;
        table {
            margin: 8px 0 0;
            border-collapse: collapse;
            text-align: left;
            vertical-align: top;
            font-size: 15px;
            border: 0;
            
            @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
                display: block;
            }
            
            thead {
                @media (max-width: $screen-xs-min - 1px) {
                    display: none;
                }
            }
            
            thead tr {
                border-bottom: 1px solid $color-0;
                background-image: none;
                
                @media (max-width: $screen-xs-min - 1px) {
                    background-image: url(../simg/table-row-separator.gif);
                    background-repeat: repeat-x;
                    background-position: 0 12px;
                }
                
            }
            tbody tr+tr {
                background-image: url(../simg/table-row-separator.gif);
                background-repeat: repeat-x;
                background-position: 0 0;
            }
        }
        tr {
            margin: 0;
            padding: 0;
            border: 0;
            @media (max-width: $screen-sm-max)  {
                border-top: 1px solid $colorWhite;
                td:first-child {
                    padding-top: 30px;
                }
                td:last-child {
                    padding-bottom: 30px;
                }
            }
            th {
                padding: 0 0 2px 0;
                font-weight: normal;
                vertical-align: top;
                border-bottom: none;
            }
            td {
                padding: 18px 0 14px;
                border: 0;
                
                @media (min-width: $screen-md-min)  {                    
                    border-top: 1px solid $colorWhite;
                }
                // border: 1px solid #AFAFAF; //TODO
                font-weight: normal;
                vertical-align: top;
                
                // @media (max-width: $screen-xs-max) {
                //     text-align: center;
                //     * {
                //         float: none !important;
                //         margin: 0 auto;
                //     }
                // }
                
                &:first-child {
                    @media (max-width: $screen-xs-min - 1px) {
                        padding-top: 30px;
                    }
                }
                
                &:last-child {
                    @media (max-width: $screen-xs-min - 1px) {
                        padding-bottom: 30px;
                    }
                }
                
            }
            &:first-child {
                td {
                    padding-top: 18px;
                }
                .product-variant-mainphoto {
                    padding-top: 13px;
                }
            }
            .product-variant-mainphoto {
                // width: 89px;
                width: 170px;
                padding-top: 8px;
                padding-left: 1px;
                img {
                    display: block;
                    float: left;
                    max-width: 110px;
                }
            }
            .product-variant-title {
                // width: 106px;
                width: 156px;
                padding-left: 20px;
                padding-right: 10px;
                font-size: 16px;
                text-transform: uppercase;
                @media (max-width: $screen-sm-max) {
                    text-align: center;
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
            .product-variant-features {
                // width: 320px;
                padding-left: 11px;
                div {
                    float: left;
                    clear: both;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    .single-line-flex {
                        
                        display: flex;
                    
                        @media (max-width: $screen-sm-max) {
                            justify-content: flex-start;
                        }
                    
                        .variant-checkbox {
                            
                            margin-right: 5px;
                            
                            input {
                                // display: block;
                                
                                @media (max-width: $screen-sm-max) {
                                    // display: inline-block;
                                }
                                
                                @media (max-width: $screen-xs-max) {
                                    // margin-top: 20px;
                                }
                                
                                // float: left;
                                // clear: left;
                                // margin: 1px 5px 5px 0;
                            }
                        }
                        .variant-description {
                            // margin-bottom: 2px;
                            // display: block;
                            // float: left;
                            // clear: right;
                            // max-width: 170px;
                        }
                    }
                }
                div+div {
                    margin-top: 5px;
                }
            }
            .product-variant-qty {
                // width: 58px;
                width: 84px;
                padding-left: 6px;
                text-align: center;
                @media (max-width: $screen-sm-max) {
                    text-align: center;
                }
                
                // @media (max-width: $screen-xs-min - 1px) {
                //     text-align: center;
                // }
                
                input {
                    width: 42px;
                    
                    // height: 18px;
                    height: 42px;
                    
                    border: 1px solid $colorWhite;
                    font-size: 24px;
                    text-align: center;
                    background-color: transparent;
                    color: $colorWhite;
                }
            }
            .product-variant-price {
                // width: 60px;
                width: 120px;
                padding-left: 5px;
                
                .product-price-placeholder,
                .product-price-special-placeholder {
                    display: block;
                    @media (max-width: $screen-sm-max) {
                        margin: 0 auto;
                        text-align: center;
                    }
                }
                
                &.product-special-price .product-price-placeholder {
                    text-decoration: line-through;
                    font-size: 20px;
                }
            }
            .product-variant-availability {
                // width: 60px;
                width: 120px;
                padding-left: 3px;
                padding-right: 3px;
                text-align: center;
                @media (max-width: $screen-sm-max) {
                    text-align: center;
                }
            }
            .product-variant-add-to-compare {
                display: none;
                width: 87px;
                padding-left: 11px;
                a {
                    padding-right: 8px;
                    padding-left: 21px;
                    background-image: url(../simg/icn-add-to-compare.png);
                    background-repeat: no-repeat;
                    background-position: 6px 6px;
                    background-color: $color-2;
                    color: $color-0;
                }
            }
            .product-variant-add-to-cart {
                // width: 67px;
                width: 71px;
                padding-left: 4px;
                text-align: center;
                a {
                    // padding-right: 6px;
                    // padding-left: 25px;
                    // background-image: url(../simg/icn-add-to-cart.png);
                    // background-repeat: no-repeat;
                    // background-position: 8px 6px;
                    // background-color: $color-4;
                    // color: $color-2;
                }
            }
        }
    }
    .product-related {
        @include clearfix;
        float: left;
        clear: both;
        width: 100%;
        margin: 80px 0 0 0;
        .products-boxes .product-box .product-actions {
            .product-add-to-cart {
                display: none;
            }
            .product-more {
                width: 100%;
                a.btn.btn-more {
                    float: left;
                    // width: 158px;
                    width: 216px;
                    padding-right: 40px;
                    text-align: right;
                    text-indent: 0;
                    background-position: 185px 8px;
                }
            }
        }
    }
}




.ProductAsk,
.ProductRecommend {
    float: left;
    clear: both;
    
    // width: 216px;
    width: 260px;
    // height: 14px;
    height: 22px;
    
    line-height: 15px;
    margin-top: 9px;
    padding-right: 10px;
    padding-left: 34px;
    color: $color-2;
    text-align: left;
    
    &.opened {
        // height: 102px;
        height: 110px;
        cursor: default;
        &.loading {
            background-image: url(../simg/ajax-loader.gif);
            background-repeat: no-repeat;
            background-position: center center;
            .product-ask-content,
            .product-recommend-content {
                display: none;
                position: relative;
                float: left;
                clear: both;
                width: 100%;
                background: none repeat scroll 0 0 $color-0;
                z-index: 2;
            }
        }
        // .product-ask-content {
        //     display: block;
        // }
        a.product-ask-show,
        a.product-recommend-show {
            cursor: default;
            span {
                display: none;
                &.product-ask-decoration,
                &.product-recommend-decoration {
                    position: absolute;
                    display: inline;
                    top: 4px;
                    left: 12px;
                    font-weight: bold;
                    text-decoration: none;
                    color: $color-2;
                }
            }
        }
    }
    .sent-info {
        display: none;
    }
}

.ProductAskContent,
.ProductRecommendContent {
    // display: none;
    position: relative;
    // float: left;
    clear: both;
    width: 100%;
    background: none repeat scroll 0 0 $colorBlack;
    z-index: 2;
    textarea {
        // float: left;
        // clear: both;
        // width: 210px;
        width: 320px;
        max-width: 100%;
        
        // height: 55px;
        height: 200px;
        
        margin: 0 0 0;
        padding: 3px;
        border: 1px solid $colorWhite;
        resize: none;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
        text-align: left;
        background-color: $colorBlack;
        color: $colorWhite;
        &.i-error {
            border-color: $colorRed;
        }
    }
    input {
        // float: left;
        // clear: left;
        // width: 100px;
        width: 320px;
        max-width: 100%;
        margin: 0 0 0;
        padding: 3px;
        border: 1px solid $colorWhite;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
        background-color: $colorBlack;
        color: $color-2;
        &.i-error {
            border-color: $colorRed;
        }
    }
    a {
        // float: right;
        // clear: right;
        display: inline-block;
        margin: 0 0 0;
        padding: 5px 10px;
        white-space: nowrap;
        color: $colorWhite;
        border: 1px solid $colorWhite;
    }
    
    .sent-info {
        display: none;
    }
    
    &.sent .sent-info {
        display: block;
        background: none repeat scroll 0 0 $colorBlack;
        height: 75px;
        // left: 25px;
        padding-top: 30px;
        padding-right: 5px;
        // position: absolute;
        text-align: center;
        // top: 0;
        width: 100%;
        // z-index: 5;
    }
}
// a.product-ask-show {
//     position: absolute;
//     top: 0;
//     left: 0;
    
//     // width: 220px;
//     width: 250px;
    
//     height: 18px;
//     padding-top: 4px;
//     padding-left: 34px;
//     background-image: url(../simg/arrow-thick-white-right.png);
//     background-repeat: no-repeat;
//     background-position: 242px 7px;
//     color: $color-2;
//     span.product-ask-decoration {
//         position: absolute;
//         display: inline;
//         top: 4px;
//         left: 12px;
//         font-weight: bold;
//         text-decoration: none;
//         color: $color-2;
//     }
// }
// 
// 


// .SamplersChooserWrp {
//     display: block;
//     margin: 0 auto;
//     position: relative;
//     z-index: 1;
//     max-width: 100%;
    
//     @media (min-width: $screen-xs-min) {
//         position: absolute;
//         left: 50%;
//         bottom: 10%;
//         transform: translateX(-50%);
//     }
//     @media (min-width: $screen-md-min) {
//         left: 50%;
//         transform: translateX(-62%);
//     }
//     @media (min-width: $screen-lg-min) {
//         left: 50%;
//         transform: translateX(-71%);
//     }
    
//     .SamplersChooser {
//         display: block;
//         max-width: 100%;
//         margin: 10px auto;
//         border: 0;
//         padding: 5px 10px 5px 10px;
//         border-radius: 10px;
//         background-color: #dcdcdc;
//         outline: 0;
//         option {
//             background-color: #dcdcdc;
//         }
//     }
// }
.SamplersChooserWrp {
    display: block;
    margin: 10px auto;
    text-align: center;
    position: relative;
    z-index: 1;
//     max-width: 100%;
    // width: 35%;
    button {
        // width: 100%;
        background-color: $colorGray;
        color: $colorBlack;
        padding: 10px;
        font-size: 15px;
        text-transform: none;
        &:hover,
        &:focus {
            color: $colorBlack;
        }
    }
    .dropdown-menu {
        background-color: lighten($colorGray, 20%);
        color: $colorBlack;
        min-width: 253px;
        a:hover {
            background-color: $colorGray;
        }
    }
    @media (min-width: $screen-xs-min) {
        position: absolute;
        left: 50%;
        bottom: 10%;
        transform: translateX(-50%);
    }
    @media (min-width: $screen-md-min) {
        left: 50%;
        transform: translateX(-62%);
    }
    @media (min-width: $screen-lg-min) {
        left: 0;
        width: 85%;
        transform: translateX(0);
        .dropdown-menu {
            left: 50%;
            transform: translateX(-41%);
        }
    }
    
//     .SamplersChooser {
//         display: block;
//         max-width: 100%;
//         margin: 10px auto;
//         border: 0;
//         padding: 5px 10px 5px 10px;
//         border-radius: 10px;
//         background-color: #dcdcdc;
//         outline: 0;
//         option {
//             background-color: #dcdcdc;
//         }
//     }
}

.ProductGallery {
    // background-color: #003E06;
    width: 100%;
    // height: 1px;
    // padding-bottom: 100%;
    position: relative;
    display: none;
    .ProductGalleryWrp {
        @include clearfix;
        // position: relative;
        // background-color: #4A4D00;
        margin: 0 10%;
        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            margin: 0 11%;
        }
        @media (max-width: $screen-xs-max) {
            margin: 0 13%;
        }
        // height: 100%;
        // visibility: hidden;
        opacity: 0;
        transition-property: opacity;
        transition-duration: .5s;
        
        .ProductGallerySlider {
            // background-color: #001549;
            // display: flex;
        }
    }
    
    
}

.lightSlider > * {
    // height: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // align-content: center;
    // flex: 1;
    cursor: pointer;
        // max-width: 100% !important;
    // text-align: center;
    // position: relative;
    // max-width: 100% !important;
    // max-height: 100% !important;
    // height: 280px;
    img {
        
        // position: absolute;
        // top: 50%;
        // transform: translateY(-50%);
        // height: 100%;
        // width: auto;
        display: block;
        // vertical-align: middle;
        // align-self: center;
        // flex: 0 0 auto !important;
        margin: 0 auto;
        max-width: 100% !important;
        max-height: 100% !important;
        // border: 1px solid red;
    }
}

.lSSlideOuter > .lSSlideWrapper > .lSAction {
    display: none !important;
}
.ProductGallery > .lSAction {
    visibility: hidden;
}

.lSSlideOuter {
    
    .lSPager.lSGallery {
        
        @media (max-width: $screen-xs-max) {
            margin-top: 0 !important;
        }
        
        li {
            padding: 5px;
            // border: 1px solid green;
            a {
                img {
                    margin: 0 auto;
                    opacity: .5;
                    transition-duration: .5;
                    transition-property: opacity;
                }
            }
            
            &.active,
            &:hover {
                border-radius: 0;
                
                a {
                    img {
                        opacity: 1;
                    }
                }
            }
        }
    }
    
}

.lSAction {
    > a {
        z-index: 999;
        background-image: none;
        top: 46% !important;
    }
    
    > .lSNext {
        background-image: url('../simg/arrows/arrow-right.png');
        background-repeat: no-repeat;
        background-position: center !important;
        width: 29px;
        height: 48px;
        right: 25px !important;
    }

    > .lSPrev {
        background-image: url('../simg/arrows/arrow-left.png');
        background-repeat: no-repeat;
        background-position: center !important;
        width: 29px;
        height: 48px;
        left: 25px !important;
    }
}

.lg-backdrop {
    z-index: 9999999 !important;
}
.lg-outer {
    z-index: 99999999 !important;
}
