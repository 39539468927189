#container {
    float: left;
    width: 100%;
}
.IdHeaderWrapper {
    width: 100%;
    float: left;
    // background-color: $colorBlack;
    // background-image: url(../simg/header-back.gif);
    // background-repeat: repeat-x;
    .IdHeader {
        position: relative;
        margin: 0 auto;
        // width: 950px;
        width: 100%;
        // height: 362px;
        height: auto;
    }
}
.IdMiddleWrapper {
    width: 100%;
    float: left;
    // background-color: $colorBlack;
    .IdMiddle {
        @include clearfix;
        // background-color: #413131; // --- test
        position: relative;
        
        // width: 950px;
        // max-width: 950px;
        
        margin: 60px auto 50px auto;
        // #left {
        //     float: left;
            
        //     // width: 204px;
        //     //width: 238px;
        //     // max-width: 238px;
            
        //     margin: 0;
            
        //     // padding: 0 34px 0 0;
            
        //     // background-color: #BCE5FF; // --- test
            
        //     @media (max-width: $screen-sm-max) {
        //         // display: none;
                
        //         // position: absolute;
        //         position: fixed;
        //         // top: 0;
        //         top: 52px;
        //         left: 0;
        //         z-index: 10001;
        //         height: 100%;
        //         //overflow-y: scroll;
        //         background-color: $color-1;
        //     }
        // }
        // #right {
        //     // width: 712px;
        //     // max-width: 712px;
            
        //     min-height: 300px;
        //     float: right;
        //     margin: 0;
        //     padding: 0;
        //     // background-color: #C3FFC2; // --- test
        // }
    }
}
.IdFooterWrapper {
    width: 100%;
    float: left;
    margin: 43px 0 0;
    
    .IdFooter {
        position: relative;
        background-color: $colorTer;
        // width: 950px;
        max-width: 1300px;
        width: 100%;
        
        // height: 121px;
        // min-height: 333px;
        min-height: 150px;
        
        margin: 0 auto;
        padding: 0;
    }
}